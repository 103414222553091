import type { accessLevels } from '@evelia/common/constants'
import { getAccessRoleOverrideLevel, isFeatureKey } from '@evelia/common/features'
import type { EmployeeModel } from '@evelia/common/types/employee'
import type { ValueOf } from 'type-fest'

import type { EmployeeLevelModel } from '../api/rtk/employeeLevelsApi'
import {
  findFeaturePermissionsByKey,
  getSystemCustomerPermissions
} from '../components/Authentication/featurePermissions'
import { type actionKeys, serviceLevelValues } from '../constants'
import { createArgumentSelector, createEveliaSelector } from '../helpers/typedSelectorHelpers'
import type { EveliaRootState } from '../reducerTypes'
import { findCurrentEmployee, findCurrentEmployeeLevel } from './employeeSelectors'

/**
 * requireInternalPermission: boolean | null | [string]
 */
const hasAccessWithInternalPermission = ({ requireInternalPermission }: Partial<ReturnType<typeof findFeaturePermissionsByKey>>, whoAmIData: EveliaRootState['whoAmI']['data'], featureKey?: ValueOf<typeof actionKeys>) => {
  // If there's no requirement, grant access
  if(!requireInternalPermission || !featureKey) {
    return true
  }

  // If we don't know who user is or what is enabled, don't grant access
  if(!whoAmIData?.internallyEnabledFeatures) {
    return false
  }

  // If required permissions is an array, as long as all of them is enabled, grant access
  if(Array.isArray(requireInternalPermission)) {
    return requireInternalPermission.every(requiredFeatureKey => whoAmIData.internallyEnabledFeatures.includes(requiredFeatureKey.toUpperCase()))
  }

  // Grant access if feature is enabled
  return whoAmIData.internallyEnabledFeatures.includes(featureKey.toUpperCase())
}

const hasAccessWithAccessLevel = (featureKey, { minAccessLevel }, employeeLevel: EmployeeLevelModel, ignoreAccessLevel: boolean, employee: EmployeeModel) => {
  const employeeAccessLevel = employeeLevel.accessLevel
  const isSalaryCalculator = employee?.isSalaryCalculator
  const accessRoleOverride = employeeLevel.accessRoleOverride
  const accessRoleOverrideAccessLevel = isFeatureKey(featureKey) ? getAccessRoleOverrideLevel({ featureKey, accessRoleOverride, isSalaryCalculator }) : null
  return ignoreAccessLevel || (accessRoleOverrideAccessLevel ?? employeeAccessLevel) >= minAccessLevel
}

export const getHasServiceLevelAccess = createArgumentSelector(
  [
    state => state.whoAmI.data,
    (state, featureKey: ValueOf<typeof actionKeys>) => featureKey
  ],
  (whoAmIData, featureKey) => {
    const { minServiceLevel } = findFeaturePermissionsByKey(featureKey)
    return serviceLevelValues[whoAmIData?.serviceLevel] >= minServiceLevel
  }
)

export const getHasAccessToFeature = createArgumentSelector(
  [
    state => state.whoAmI.data,
    state => state.systemCustomer.settingsData.settings,
    state => findCurrentEmployeeLevel(state),
    state => getSystemCustomerPermissions(state.systemCustomer),
    (state, featureKey?: ValueOf<typeof actionKeys>) => featureKey,
    (state, featureKey?, ignoreAccessLevel?: boolean) => !!ignoreAccessLevel,
    (state, featureKey?, ignoreAccessLevel?, minAccessLevel?: ValueOf<typeof accessLevels>) => minAccessLevel,
    state => findCurrentEmployee(state)
  ],
  (whoAmIData, settings, employeeLevel, systemCustomerPermissions, featureKey, ignoreAccessLevel, minAccessLevel, employee) => {
    /* If minAccessLevel is defined but featureKey is not,
     * only look at minAccessLevel.
     * Otherwise direct minAccessLevel is ignored
     * and uses accessLevel set in featurePermissions */
    const employeeAccessLevel = employeeLevel.accessLevel
    if(!featureKey && minAccessLevel) {
      return employeeAccessLevel >= minAccessLevel
    }
    const permissions = findFeaturePermissionsByKey(featureKey)
    const { minServiceLevel, stage } = permissions
    const { systemCustomerStage } = systemCustomerPermissions

    return !!(
      serviceLevelValues[whoAmIData?.serviceLevel] >= minServiceLevel &&
      hasAccessWithInternalPermission(permissions, whoAmIData, featureKey) &&
      hasAccessWithAccessLevel(featureKey, permissions, employeeLevel, ignoreAccessLevel, employee) &&
      systemCustomerStage >= stage &&
      (permissions.requireSetting ? permissions.requireSetting(settings, employee) : true)
    )
  }
)

export const getHasAccessToFeatureCallback = createArgumentSelector(
  [
    state => state.whoAmI.data,
    state => state.systemCustomer.settingsData.settings,
    state => findCurrentEmployee(state),
    state => findCurrentEmployeeLevel(state),
    state => getSystemCustomerPermissions(state.systemCustomer),
    (__state, ignoreAccessLevel) => !!ignoreAccessLevel
  ],
  (whoAmIData, settings, employee, employeeLevel, systemCustomerPermissions, ignoreAccessLevel) => {
    const { systemCustomerStage } = systemCustomerPermissions
    return (featureKey: ValueOf<typeof actionKeys>) => {
      const permissions = findFeaturePermissionsByKey(featureKey)
      const { minServiceLevel, stage } = permissions
      return !!(
        serviceLevelValues[whoAmIData?.serviceLevel] >= minServiceLevel &&
        hasAccessWithInternalPermission(permissions, whoAmIData, featureKey) &&
        hasAccessWithAccessLevel(featureKey, permissions, employeeLevel, ignoreAccessLevel, employee) &&
        systemCustomerStage >= stage &&
        (permissions.requireSetting ? permissions.requireSetting(settings, employee) : true)
      )
    }
  }
)

export const getDenominationSystemType = createEveliaSelector(
  [
    state => state.whoAmI.data
  ],
  data => data.denominationSystemType
)
