import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router'
import { mfaStatusOk } from '@evelia/common/constants'

import type { EveliaRootState } from '../../reducerTypes'
import LoginHeader from '../LoginPage/LoginHeader'

const AuthorizationWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const isRegisterRoute = !!matchPath<{ cuid?: string }>(location.pathname, {
    path: '/register/:cuid?',
    exact: true,
    strict: false
  })
  const { data } = useSelector((state: EveliaRootState) => state.whoAmI)
  const isUserAuthenticated = useMemo(() => data.mfaStatus === mfaStatusOk && data.serviceLevel && !isRegisterRoute, [data, isRegisterRoute])
  if(!isUserAuthenticated) {
    return <LoginHeader />
  }
  return children
}

export default AuthorizationWrapper
