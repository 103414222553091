import { emptyArray } from '@evelia/common/constants'
import {
  dateFormats,
  isSameOrBefore,
  moment,
  now,
  withFormat
} from '@evelia/common/dateHelpers'
import { sortByProperty } from '@evelia/common/helpers'
import type { CombinedVatRateModel, VatClassModel, VatCodeModel } from '@evelia/common/types'
import memoize from 'micro-memoize'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { vatCodeApi } from '../api/rtk/vatCodeApi'
import { vatTypes, vatTypeTitles } from '../constants'
import type { EveliaRootState } from '../reducerTypes'
import { vatClassEntitySelectors, vatRateEntitySelectors } from '../slices/vatSlices'

export type VatType = keyof typeof vatTypes | 'all'

interface VatCodeRecordsResponse { data?: { records: VatCodeModel[] } }
export const getVatCodeById = createCachedSelector(
  (response: VatCodeRecordsResponse) => response?.data?.records,
  (__response, id: number | null) => id,
  (vatCodes, id) => vatCodes ? vatCodes.find(vatCode => vatCode.id === id) : null
)((__response, id) => `${id}`)

export const selectVatCodesResponse = vatCodeApi.endpoints.getVatCodes.select(undefined)

/**
 * NOTE: It is important that vat codes are sorted by descending 'validAfter'!
 * Order is used for finding latest active vat code when comparing with delivery dates
 */
const sortByDeliveryDate = sortByProperty('validAfter', true)
export const findCombinedVatRates = createSelector(
  vatClassEntitySelectors.selectAll,
  vatRateEntitySelectors.selectAll,
  // @ts-expect-error selector doesn't recognize EveliaRootState as RootState
  (state: EveliaRootState) => selectVatCodesResponse(state).data?.records,
  (state: EveliaRootState) => state.systemCustomer.settingsData.settings.defaultVatClassId,
  (vatClasses, vatRates, vatCodes, defaultVatClassId) => {
    const currentTime = now()
    return vatCodes
      ? vatCodes.map(vatCode => {
        const vatClass = vatClasses.find(({ id }) => id === vatCode.vatClassId)
        const vatRate = vatRates.find(({ id }) => id === vatCode.vatRateId)

        if(!vatClass || !vatRate) {
          return null
        }
        const isDefault = vatClass.id === defaultVatClassId && vatCode.isSystem && isSameOrBefore(vatRate.validAfter, currentTime)

        const combinedVatRate: CombinedVatRateModel = {
          id: vatCode.id,
          name: vatCode.name,
          eveliaVatCode: vatCode.eveliaVatCode,
          fennoaVatCodeSales: vatCode.fennoaVatCodeSales,
          fennoaVatCodePurchase: vatCode.fennoaVatCodePurchase,
          netvisorVatCode: vatCode.netvisorVatCode,
          vatClassId: vatCode.vatClassId,
          isSystem: vatCode.isSystem,
          isEnabled: vatCode.isEnabled,

          vatClassName: vatClass.name,
          description: vatClass.description,
          identifier: vatClass.identifier,
          isReversed: vatClass.isReversed,
          isGeneric: vatClass.isGeneric,
          isVatless: vatClass.isVatless,

          vatRateId: vatRate.id,
          rate: vatRate.rate,
          validAfter: vatRate.validAfter,

          isDefault
        }
        return combinedVatRate
      })
        .filter(value => !!value)
        .sort(sortByDeliveryDate)
      : emptyArray as CombinedVatRateModel[]
  }
)

export const getVatRateByVatType = (combinedVatRates, vatType) => {
  switch(vatType) {
    case vatTypes.reversedVat:
      return combinedVatRates.find(combinedVatRate => combinedVatRate.isReversed && combinedVatRate.isSystem)
    case vatTypes.euSales:
      return combinedVatRates.find(combinedVatRate => combinedVatRate.isVatless && combinedVatRate.isSystem)
    default:
      return null
  }
}

const getVatClassesByVatType = (vatClasses, vatType) => {
  switch(vatType) {
    case vatTypes.reversedVat:
      return vatClasses.filter(combinedVatRate => combinedVatRate.isReversed)
    case vatTypes.euSales:
      return vatClasses.filter(combinedVatRate => combinedVatRate.isVatless)
    default:
      return emptyArray
  }
}

export const findDefaultVatRate = createSelector(
  findCombinedVatRates,
  (_state: EveliaRootState, vatType?: VatType) => vatType,
  (combinedVatRates, vatType) => {
    const vatTypeVatRate = getVatRateByVatType(combinedVatRates, vatType)
    return vatTypeVatRate || combinedVatRates.find(vatRate => vatRate?.isDefault)
  }
)

export const findVatClassesWithVatType = createCachedSelector<EveliaRootState, VatType, VatClassModel[], VatType | undefined, VatClassModel[]>(
  vatClassEntitySelectors.selectAll,
  (_state, vatType) => vatType,
  getVatClassesByVatType
)((_state, vatType) => `${vatType}`)

export const getVatTypeTitle = vatType => vatTypeTitles[vatType] || vatTypeTitles.default

export const findVatRateWithId = vatRateEntitySelectors.selectById

export const findCombinedVatRateWithClassId = createCachedSelector(
  findCombinedVatRates,
  (_state: EveliaRootState, vatClassId: number) => Number(vatClassId),
  (_state: EveliaRootState, _vatClassId: number, deliveryDate?: string) => deliveryDate,
  (combinedVatRates, vatClassId, deliveryDate) => {
    const deliveryDateDate = moment(deliveryDate).isValid() ? deliveryDate : undefined // Given date or undefined which defaults to current time
    const validAfter = withFormat(deliveryDateDate, dateFormats.isoDate)
    return combinedVatRates
      .find(combinedVatRate =>
        combinedVatRate?.vatClassId === vatClassId &&
        isSameOrBefore(combinedVatRate.validAfter, validAfter)
      )
  })((_state, vatClassId, deliveryDate) => `${vatClassId}_${deliveryDate?.substr(0, 10)}`) // assumes ISO date: '2024-07-01T12:00:00' -> '2024-07-01'

export const findVatRates = createCachedSelector(
  findCombinedVatRates,
  (__state, vatType) => vatType,
  (combinedVatRates, vatType) => {
    const vatTypeVatRate = getVatRateByVatType(combinedVatRates, vatType)
    return vatTypeVatRate
      ? [vatTypeVatRate]
      : combinedVatRates.filter(vatRate => !vatRate?.isReversed)
  })((_state, vatType) => `${vatType}`)

export const findVatRateWithIdOrDefault = createCachedSelector(
  findCombinedVatRates,
  (__state: EveliaRootState, vatCodeId: number) => Number(vatCodeId),
  (state: EveliaRootState, __vatCodeId: number, vatType: VatType) => findDefaultVatRate(state, vatType),
  (combinedVatRates, vatCodeId, defaultVatRate) =>
    combinedVatRates.find(vatRate => vatRate?.id === vatCodeId) || defaultVatRate
)((_state, vatCodeId, vatType) => `${vatCodeId}_${vatType}`)

export const getVatRateOptions = createCachedSelector(
  findCombinedVatRates,
  (_state, hideReversed) => !!hideReversed,
  (combinedVatRates, hideReversed) => mapVatRateOptions(combinedVatRates.filter(vatRate => !hideReversed || !vatRate?.isReversed))
)((_state, hideReversed) => `${hideReversed}`)

// Helpers
export const formatVatRateText = vatRate => `${vatRate.rate}`

export const mapVatRateOptions = memoize(vatRates => vatRates.map(vatRate => (
  { value: vatRate.id, text: formatVatRateText(vatRate) }
)))

export const findDefaultVatClasses = createSelector(
  vatClassEntitySelectors.selectAll,
  vatClasses => vatClasses.filter(vatClass => !vatClass.isReversed)
)

/**
 * NOTE: use get with provided data and find when using state
 * Looks vat rate from a provided array
 */
export const getCombinedVatRateWithId = createCachedSelector(
  (combinedVatRates: CombinedVatRateModel[]) => combinedVatRates,
  (_combinedVatRates: CombinedVatRateModel[], vatCodeId: number) => Number(vatCodeId),
  (combinedVatRates, vatCodeId) => combinedVatRates.find(combinedVatRate => combinedVatRate.id === vatCodeId)
)((_combinedVatRates, vatCodeId) => `${vatCodeId}`)

export const getDefaultVatRate = createCachedSelector(
  (combinedVatRates: CombinedVatRateModel[]) => combinedVatRates,
  (_combinedVatRates: CombinedVatRateModel[], vatCodeId: number) => Number(vatCodeId),
  (_combinedVatRates: CombinedVatRateModel[], _vatCodeId: number, vatType: VatType) => vatType,
  (combinedVatRates, vatCodeId, vatType) => {
    const vatTypeVatRate = getVatRateByVatType(combinedVatRates, vatType)
    return vatTypeVatRate || combinedVatRates.find(({ id }) => id === vatCodeId) || combinedVatRates.find(({ isDefault }) => isDefault)
  }
)((_combinedVatRates, vatCodeId, vatType) => `${vatCodeId}_${vatType}`)

export const getCombinedVatRateWithClassId = createCachedSelector(
  (combinedVatRates: CombinedVatRateModel[]) => combinedVatRates,
  (_combinedVatRates: CombinedVatRateModel[], vatClassId: number) => Number(vatClassId),
  (_combinedVatRates: CombinedVatRateModel[], _vatClassId: number, deliveryDate: string) => deliveryDate,
  (combinedVatRates, vatClassId, deliveryDate) => {
    const currentTime = now()
    return combinedVatRates.find(combinedVatRate =>
      combinedVatRate.isSystem &&
      combinedVatRate.vatClassId === vatClassId &&
      isSameOrBefore(combinedVatRate.validAfter, deliveryDate || currentTime)
    )
  }
)((_combinedVatRates, vatClassId, deliveryDate) => `${vatClassId}_${deliveryDate?.substr(0, 10)}`) // assumes ISO date: '2024-07-01T12:00:00' -> '2024-07-01'
