import type {
  BaseFileLinkModel,
  BaseIdModel,
  FileCreateBody,
  FileModel,
  FileRelationModel
} from '@evelia/common/types'
import type { UnknownRecord } from 'type-fest'

import type { TableOptionsDefaultFilters, TableOptionsModel } from './api'

export type NodeFile = File

export interface GetFilesFilters extends TableOptionsDefaultFilters { systemTags: number | null, ignoredIds: number[] | null }
export type GetFilesArgs = TableOptionsModel<GetFilesFilters>
export interface GetFilesResponse { records: FileModel[], _embedded: { options: GetFilesArgs } }

export interface FileDownloadResponse {
  base64: string
  fileType: string
}

export type FileDownloadResult = {
  fileName: string
  fileType: string
  base64: string
} & BaseIdModel

export interface GetRelationFilesArgs { relationId: number }

export const fileRelationType = {
  PROJECTS: 'projects',
  CUSTOMERS: 'customers'
} as const
export type FileRelationType = typeof fileRelationType[keyof typeof fileRelationType]

type BaseRelationFilesRecord = BaseIdModel & { relationFiles: FileRelationModel[] }
interface RelationFilesMap {
  [fileRelationType.CUSTOMERS]: BaseRelationFilesRecord & { customerId: number }
  [fileRelationType.PROJECTS]: BaseRelationFilesRecord & { projectId: number }
}
export interface GetRelationFilesResponse<T extends FileRelationType> {
  record: RelationFilesMap[T]
  _embedded: UnknownRecord
}

export type ExtendedFileInfo = Pick<FileCreateBody, 'description' | 'systemTag' | 'employeeLevelId'>
export interface CreateFileArgs {
  file: NodeFile
  extendedInfo?: ExtendedFileInfo
}
export type CreateFileResponse = FileModel & {
  _s3Data: { fields: object, url: string } // TODO type this better
}

export type ContactFileLinkModel = BaseFileLinkModel & { contactId: number }
export type CustomerFileLinkModel = BaseFileLinkModel & { customerId: number }
export type EmployeeFileLinkModel = BaseFileLinkModel & { employeeId: number, employeeLicenceId: number }
export type InboundInvoiceFileLinkModel = BaseFileLinkModel & { inboundInvoiceId: number }
export type MachineFileLinkModel = BaseFileLinkModel & { machineId: number }
export type OfferFileLinkModel = BaseFileLinkModel & { offerId: number }
export type PurchaseOrderFileLinkModel = BaseFileLinkModel & { purchaseOrderId: number }
export type ProductFileLinkModel = BaseFileLinkModel & { productId: number }
export type ProjectFileLinkModel = BaseFileLinkModel & { projectId: number }
export type ReceiptFileLinkModel = BaseFileLinkModel & { receiptId: number }
export type ReceiverFileLinkModel = BaseFileLinkModel & { receiverId: number }
export type SalaryCalculationFileModel = BaseFileLinkModel & { salaryCalculationId: number }
export type SupplyOfferFileModel = BaseFileLinkModel & { supplyOfferId: number, attachToPdf: boolean }
export type WorkFileLinkModel = BaseFileLinkModel & { workId: number, attachToInvoice: boolean, isAttachedToInvoice: boolean, isImportant: boolean, systemTag: number, description: string | null }
