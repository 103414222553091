import {
  faEuroSign,
  faHandHolding
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

interface HandHoldingEurProps extends Omit<FontAwesomeIconProps, 'icon'> {
  title?: string
  className?: string
}

const HandHoldingEur = ({ title, className, ...props }: HandHoldingEurProps) => (
  <span className={clsx(className, 'fa-layers fa-fw')} title={title}>
    <FontAwesomeIcon {...props} icon={faHandHolding} />
    <FontAwesomeIcon icon={faEuroSign} transform='right-1 up-4 shrink-5' />
  </span>
)

export default HandHoldingEur
