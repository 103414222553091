import type { ValueOf } from 'type-fest'

import { accessLevels } from './constants.js'

export const featureKeys = {
  TARGETS: 'targets',
  TARGET_CONTACTS: 'target_contacts',
  TARGET_FILES: 'target_files',
  TARGET_MEMOS: 'target_memos',
  CUSTOMERS: 'customers',
  CUSTOMER_CONTACTS: 'customer_contacts',
  CUSTOMER_FILES: 'customer_files',
  CUSTOMER_MEMOS: 'customer_memos',
  CUSTOMER_GROUPS: 'customer_groups',
  CONTACTS: 'contacts',
  CONTACT_FILES: 'contact_files',
  CONTACT_CUSTOMERS: 'contact_customers',
  CONTACT_TARGETS: 'contact_targets',
  CONTACT_EXTRAS: 'contact_extras',
  WORK: 'work',
  WORK_ROWS: 'work_rows',
  WORK_FILES: 'work_files',
  WORK_MEMOS: 'work_memos',
  WORK_EMPLOYEES: 'WORK_EMPLOYEES',
  WORK_SCHEDULE_EVENTS: 'WORK_SCHEDULE_EVENTS',
  WORK_MACHINES: 'WORK_MACHINES',
  WORK_TYPES: 'WORK_TYPES',
  WORK_SUMMARY_STATS: 'WORK_SUMMARY_STATS',
  WORK_CUSTOM_STATES: 'WORK_CUSTOM_STATES',
  WORK_VIEW_ALL: 'WORK_VIEW_ALL',
  WORK_PURCHASE_PRICES: 'WORK_PURCHASE_PRICES',
  WORK_RECURRENCE_RULES: 'work_recurrence_rules',
  WORK_INVOICING_RULES: 'work_invoicing_rules',
  PRODUCTS: 'products',
  PRODUCT_FILES: 'product_files',
  PRODUCT_LINES: 'product_lines',
  PRODUCT_LINES_SUPPLIER_STATS: 'product_lines_supplier_stats',
  PRODUCT_TASKS: 'product_tasks',
  PRODUCT_WAREHOUSE_STATS: 'product_warehouse_stats',
  INVOICES: 'invoices',
  INVOICE_ACCOUNTINGS: 'invoice_accountings',
  INVOICE_FILES: 'invoice_files',
  INVOICE_FORM_SETTINGS: 'invoice_form_settings',
  INVOICE_MEMOS: 'invoice_memos',
  INTEREST_INVOICING: 'INTEREST_INVOICING',
  WHO_AM_I: 'who_am_i',
  LOGIN: 'login',
  EMPLOYEES: 'employees',
  EMPLOYEE_LEVELS: 'employee_levels',
  EMPLOYEE_SUBCONTRACTORS: 'employee_subcontractors',
  EMPLOYEE_INVITATIONS: 'employee_invitations',
  EMPLOYEE_ROLES: 'employee_roles',
  EMPLOYEE_LICENCES: 'employee_licences',
  EMPLOYEE_LICENCE_TYPES: 'employee_licence_types',
  EMPLOYEE_FILES: 'employee_files',
  EMPLOYEE_MEMOS: 'employee_memos',
  EMPLOYEE_EXTRAS: 'employee_extras',
  FILES: 'files',
  CUSTOMER_RELATION_FILES: 'customer_relation_files',
  PROJECT_RELATION_FILES: 'project_relation_files',
  SYSTEM_CUSTOMER: 'system_customer',
  SYSTEM_CUSTOMER_DETAILS: 'system_customer_details',
  SYSTEM_CUSTOMER_SETTINGS: 'system_customer_settings',
  SYSTEM_CUSTOMER_MAVENTA: 'system_customer_maventa',
  SYSTEM_CUSTOMER_ACCOUNTING: 'system_customer_accounting',
  SYSTEM_CUSTOMER_BANK_ACCOUNTS: 'system_customer_bank_accounts',
  SYSTEM_CUSTOMER_CREDIT_ACTIONS: 'system_customer_credit_actions',
  SYSTEM_CUSTOMER_EMAIL_SETTINGS: 'system_customer_email_settings',
  SYSTEM_CUSTOMER_TERMS_OF_SERVICE: 'system_customer_terms_of_service',
  SYSTEM_CUSTOMER_SMS_SETTINGS: 'system_customer_sms_settings',
  SYSTEM_CUSTOMER_FORM_TEMPLATE: 'system_customer_form_template',
  ACCOUNTS: 'accounts',
  DEFAULT_ACCOUNTS: 'DEFAULT_ACCOUNTS',
  DASHBOARD: 'dashboard',
  SUPPLIERS: 'suppliers',
  SUPPLIER_ORDER: 'supplier_order',
  PRODUCT_TABLE_OPTIONS: 'PRODUCT_TABLE_OPTIONS',
  SUPPLIER_TABLE_OPTIONS: 'SUPPLIER_TABLE_OPTIONS',
  INBOUND_INVOICES: 'inbound_invoices',
  INBOUND_INVOICE_ROWS: 'inbound_invoice_rows',
  INBOUND_INVOICE_FILES: 'inbound_invoice_files',
  INBOUND_INVOICE_MEMOS: 'inbound_invoice_memos',
  INBOUND_INVOICE_APPROVALS: 'inbound_invoice_approvals',
  INBOUND_INVOICE_APPROVERS: 'inbound_invoice_approvers',
  INBOUND_INVOICE_ACCOUNTINGS: 'inbound_invoice_accountings',
  INBOUND_INVOICE_RECURRENCE_RULES: 'inbound_invoice_recurrence_rules',
  INBOUND_INVOICE_RECURRENCE_OCCURRENCIES: 'inbound_invoice_recurrence_occurrencies',
  INBOUND_INVOICE_ACTIONS: 'inbound_invoice_actions',
  INBOUND_INVOICE_STATUSES: 'inbound_invoice_statuses',
  RECEIVERS: 'receivers',
  RECEIVER_FILES: 'receiver_files',
  RECEIVER_APPROVERS: 'RECEIVER_APPROVERS',
  PACKET_INDUSTRIES: 'PACKET_INDUSTRIES',
  PRICING_RULE_SETS: 'pricing_rule_sets',
  PRICING_RULE_FALLBACKS: 'PRICING_RULE_FALLBACKS',
  PRICING_RULES: 'pricing_rules',
  INVITATIONS: 'invitations',
  FILL_OUT_FORMS: 'fill_out_forms',
  WORK_RECORD_PRODUCTS: 'work_record_products',
  WORK_RECORDS: 'work_records',
  WORK_RECORD_SALARY_EVENTS: 'WORK_RECORD_SALARY_EVENTS',
  WORK_RECORD_MEMOS: 'WORK_RECORD_MEMOS',
  POSTAL_CODES: 'postal_codes',
  RECEIPT: 'receipt',
  RECEIPTS: 'receipts',
  RECEIPT_MEMOS: 'receipt_memos',
  RECEIPT_FILES: 'receipt_files',
  RECEIPT_ACCOUNTINGS: 'receipt_accountings',
  SALARY_TYPES: 'SALARY_TYPES',
  WORK_RECORD_PRODUCT_SALARY_TYPES: 'WORK_RECORD_PRODUCT_SALARY_TYPES',
  WORK_RECORD_PRODUCT_PRICING_RULES: 'WORK_RECORD_PRODUCT_PRICING_RULES',
  PRE_HANDOVER_INSPECTIONS: 'PRE_HANDOVER_INSPECTIONS',
  PRE_HANDOVER_INSPECTION_ROWS: 'PRE_HANDOVER_INSPECTION_ROWS',
  PRE_HANDOVER_INSPECTION_MEMOS: 'WORK_PRE_HANDOVER_INSPECTION_MEMOS',
  PROJECTS: 'projects',
  PROJECT_ADDITIONAL_PERSONS: 'project_additional_persons',
  PROJECT_CONTACTS: 'project_contacts',
  PROJECT_EMPLOYEES: 'project_employees',
  PROJECT_FILES: 'project_files',
  PROJECT_MEMOS: 'project_memos',
  PROJECT_INVOICES: 'project_invoices',
  PROJECT_INBOUND_INVOICES: 'project_inbound_invoices',
  PROJECTS_BUDGET: 'projects_budget',
  PROJECTS_BUDGET_RECURSIVE: 'projects_budget_recursive',
  PROJECTS_BUDGET_BY_DENOMINATION: 'projects_budget_by_denomination',
  PROJECT_EXTRA_BUDGETS: 'project_extra_budgets',
  PROJECT_EXTRA_EXPENSES: 'project_extra_expenses',
  PROJECT_DENOMINATION_BUDGETS: 'project_denomination_budgets',
  PROJECT_TYPES: 'project_types',
  INSTALMENTS: 'instalments',
  FINANCE: 'finance',
  REPORT_WORK_RECORDS: 'report_work_records',
  REPORTS: 'reports',
  SETTINGS: 'settings',
  SETTINGS_WIZARD: 'settings_wizard',
  CALENDAR: 'calendar',
  QUICK_PRODUCTS: 'quick_products',
  CASH_RECEIPTS: 'cash_receipts',
  CASH_RECEIPTS_FILES: 'cash_receipts_files',
  CASH_RECEIPTS_PDF: 'cash_receipts_pdf',
  CASH_RECEIPTS_ROWS: 'cash_receipts_rows',
  OFFERS: 'OFFERS',
  OFFER_FILES: 'OFFER_FILES',
  OFFER_MEMOS: 'OFFER_MEMOS',
  OFFER_POSTS: 'OFFER_POSTS',
  OFFER_POST_ROWS: 'OFFER_POST_ROWS',
  OFFER_DEFAULTS: 'OFFER_DEFAULTS',
  OFFER_CUSTOM_STATES: 'OFFER_CUSTOM_STATES',
  OFFER_ANNOTATION: 'OFFER_ANNOTATION',
  PACKETS: 'PACKETS',
  RTV: 'RTV',
  INSTALLATIONS: 'INSTALLATIONS',
  PACKET_INSTALLATIONS: 'PACKET_INSTALLATIONS',
  PACKET_INSTALLATION_PACKETS: 'PACKET_INSTALLATION_PACKETS',
  PACKET_INSTALLATION_TASKS: 'PACKET_INSTALLATION_TASKS',
  PACKET_INSTALLATION_PRODUCTS: 'PACKET_INSTALLATION_PRODUCTS',
  WAGE_INCOME_TYPES: 'wage_income_types',
  SALARY_GROUP: 'salary_group',
  PACKET_MATRICES: 'PACKET_MATRICES',
  SYSTEM_CUSTOMER_ANNUAL_INVOICINGS: 'SYSTEM_CUSTOMER_ANNUAL_INVOICINGS',
  SYSTEM_CUSTOMER_APPROVERS: 'SYSTEM_CUSTOMER_APPROVERS',
  SYSTEM_MESSAGES: 'SYSTEM_MESSAGES',
  SYSTEM_MESSAGES_VIEW_ALL: 'SYSTEM_MESSAGES_VIEW_ALL',
  WORK_SYSTEM_MESSAGES: 'WORK_SYSTEM_MESSAGES',
  EMPLOYEE_SYSTEM_MESSAGES: 'EMPLOYEE_SYSTEM_MESSAGES',
  PRODUCT_GROUPS: 'product_groups',
  SUPPLY_OFFERS: 'SUPPLY_OFFERS',
  SUPPLY_OFFER_MEMOS: 'SUPPLY_OFFER_MEMOS',
  SUPPLY_OFFER_SYSTEM_MESSAGES: 'SUPPLY_OFFER_SYSTEM_MESSAGES',
  SUPPLY_OFFER_ROWS: 'SUPPLY_OFFER_ROWS',
  SUPPLY_OFFER_TYPES: 'SUPPLY_OFFER_TYPES',
  SUPPLY_OFFER_CUSTOM_STATES: 'SUPPLY_OFFER_CUSTOM_STATES',
  SUPPLY_OFFER_FILES: 'supply_offer_files',
  SUPPLY_OFFERS_SUMMARY_STATS: 'SUPPLY_OFFERS_SUMMARY_STATS',
  SUBRECOGNITION: 'SUBRECOGNITION',
  TICKETS: 'tickets',
  TICKET_MEMOS: 'ticket_memos',
  TICKET_STATES: 'ticket_states',
  TICKET_TYPES: 'ticket_types',
  TICKET_EMPLOYEES: 'ticket_employees',
  TICKET_FILES: 'ticket_files',
  TICKET_RECURRENCE_RULES: 'ticket_recurrence_rules',
  PRH: 'prh',
  COST_PROVISIONS: 'cost_provisions',
  WAREHOUSES: 'warehouses',
  WAREHOUSE_ACTIONS: 'warehouse_actions',
  WAREHOUSE_PRODUCT_STATS: 'warehouse_product_stats',
  WORK_QUICK_FILTERS: 'work_quick_filters',
  MAVENTA: 'maventa',
  MACHINES: 'machines',
  MACHINE_MEMOS: 'machine_memos',
  MACHINE_FILES: 'machine_files',
  MACHINE_LOGS: 'machine_logs',
  MACHINE_TYPES: 'machine_types',
  MACHINE_LOG_TYPES: 'machines_machine_log_type',
  MACHINE_RECORDS: 'machine_records',
  MACHINE_RECORD_MEMOS: 'machine_record_memos',
  MACHINE_PRICING_RULES: 'machine_pricing_rules',
  OFFER_POST_STATES: 'OFFER_POST_STATES',
  PACKET_GROUPS: 'packet_groups',
  SUPPLY_OFFER_DEFAULTS: 'SUPPLY_OFFER_DEFAULTS',
  TIME_RECORDS: 'TIME_RECORDS',
  TIME_RECORD_SYSTEM_TYPES: 'TIME_RECORD_SYSTEM_TYPES',
  TIME_RECORD_SITES: 'TIME_RECORD_SITES',
  TIME_RECORD_ACCUMULATIONS: 'TIME_RECORD_ACCUMULATIONS',
  CASH_FLOW_FORECAST_EXTRA_VALUES: 'CASH_FLOW_FORECAST_EXTRA_VALUES',
  CASH_FLOW_FORECAST: 'CASH_FLOW_FORECAST',
  DENOMINATIONS: 'DENOMINATIONS',
  DENOMINATION_PACKETS: 'DENOMINATION_PACKETS',
  DENOMINATION_GROUPS: 'DENOMINATION_GROUPS',
  DENOMINATION_TABLE_OPTIONS: 'DENOMINATION_TABLE_OPTIONS',
  FORM_TYPES: 'FORM_TYPES',
  INVOICE_FORM_TYPES: 'INVOICE_FORM_TYPES',
  INBOUND_MESSAGES: 'INBOUND_MESSAGES',
  WORK_EDIT_PRICES: 'WORK_EDIT_PRICES',
  COST_CENTRES: 'COST_CENTRES',
  COST_CENTRE_GROUPS: 'COST_CENTRE_GROUPS',
  COST_CENTRE_TABLE_OPTIONS: 'COST_CENTRE_TABLE_OPTIONS',
  INVOICING_TYPE: 'INVOICING_TYPE',
  AUTHORS: 'AUTHORS',
  INBOUND_INVOICE_SALARIES: 'INBOUND_INVOICE_SALARIES',
  FORM_TEMPLATES: 'form_templates',
  WORK_FORM_TEMPLATES: 'work_form_templates',
  WORK_FORMS: 'work_forms',
  WORK_FORMS_PDF: 'work_forms_pdf',
  WORK_TICKET_TEMPLATE_LISTS: 'work_ticket_template_lists',
  MAPS: 'MAPS',
  SALARIES: 'SALARIES',
  SALARY_CALCULATION: 'SALARY_CALCULATION',
  SALARY_CALCULATION_SETTINGS: 'SALARY_CALCULATION_SETTINGS',
  PURCHASE_ORDERS: 'PURCHASE_ORDERS',
  PURCHASE_ORDER_MEMOS: 'PURCHASE_ORDER_MEMOS',
  PURCHASE_ORDER_STATES: 'PURCHASE_ORDER_STATES',
  USER_ACCOUNT: 'USER_ACCOUNT',
  JOIN_SYSTEM_CUSTOMER_ENVIRONMENT: 'JOIN_SYSTEM_CUSTOMER_ENVIRONMENT'
} as const

export const accessLevelOverrideAccountant = 'accountant'
export const accessLevelOverrideSupervisorWithoutInboundInvoices = 'supervisorWithoutInboundInvoices'
export const accessLevelOverrideSupervisorWithInboundInvoices = 'supervisorWithInboundInvoices'

// backend: AccessLevel is used to set req.context.accessLevelOverride and override req.context.user.employee_level_data.accessLevel in user accessLevel checks
// frontend: AccessLevel is used to override user accessLevel in minAccessLevel checks
type AccessLevel = ValueOf<typeof accessLevels>
// Read is used for backend only with req.method check
type Read = boolean
// Write is used for backend only with req.method check
type Write = boolean

// FeatureAccessLevelOverride is used for frontend and backend, some feature keys will only work for frontend
export interface FeatureAccessLevelOverride {
  featureKey: string
  read: Read
  write: Write
  accessLevel?: AccessLevel
}

// RouteAccessLevelOverride is used for backend only
export interface RouteAccessLevelOverride {
  routeName: string
  subRoute?: string
  read: Read
  write: Write
  accessLevel?: AccessLevel
}

export type FeatureOrRouteAccessLevelOverride = FeatureAccessLevelOverride | RouteAccessLevelOverride

export interface AccessLevelOverride {
  accessLevel: AccessLevel
  restrict: FeatureOrRouteAccessLevelOverride[]
  permit: FeatureOrRouteAccessLevelOverride[]
}

export type AccessRoleOverrideConfigKeys = typeof accessLevelOverrideAccountant |
  typeof accessLevelOverrideSupervisorWithoutInboundInvoices |
  typeof accessLevelOverrideSupervisorWithInboundInvoices

export const accessRoleOverrideConfig = {
  [accessLevelOverrideAccountant]: {
    accessLevel: accessLevels.FINANCE,
    restrict: [
      { routeName: featureKeys.INBOUND_INVOICES, subRoute: 'rows', read: false, write: true }
    ],
    permit: [
      { featureKey: featureKeys.INBOUND_INVOICES, read: true, write: true },
      { featureKey: featureKeys.INBOUND_INVOICE_ACTIONS, read: true, write: false },
      { routeName: featureKeys.RECEIVERS, read: true, write: false },
      { routeName: featureKeys.ACCOUNTS, read: true, write: false },
      { routeName: featureKeys.PRODUCT_LINES, read: true, write: false },
      { featureKey: featureKeys.INVOICES, read: true, write: true },
      { routeName: featureKeys.FORM_TYPES.toLowerCase(), read: true, write: false },
      { featureKey: featureKeys.RECEIPT, read: true, write: false },
      { routeName: featureKeys.RECEIPTS, read: true, write: true },
      { featureKey: featureKeys.REPORTS, read: true, write: true },
      { routeName: featureKeys.CUSTOMER_GROUPS, read: true, write: false },
      { routeName: featureKeys.QUICK_PRODUCTS, read: true, write: false },
      { routeName: featureKeys.PRODUCT_GROUPS, read: true, write: false },
      { routeName: featureKeys.WORK_TYPES.toLowerCase(), read: true, write: false },
      { featureKey: featureKeys.FINANCE, read: true, write: false },
      { routeName: featureKeys.FILES, read: true, write: true },
      { featureKey: featureKeys.CASH_RECEIPTS, read: true, write: false },
      { routeName: featureKeys.CUSTOMERS, read: true, write: false },
      { featureKey: featureKeys.REPORT_WORK_RECORDS, read: true, write: false },
      { routeName: featureKeys.WORK_RECORDS, read: true, write: false },
      { featureKey: featureKeys.CASH_FLOW_FORECAST, read: true, write: false },
      { featureKey: featureKeys.DASHBOARD, read: true, write: false },
      { routeName: featureKeys.WORK, read: true, write: false, accessLevel: accessLevels.DEFAULT }
    ]
  },
  [accessLevelOverrideSupervisorWithoutInboundInvoices]: {
    accessLevel: accessLevels.SUPERVISOR,
    restrict: [
      { featureKey: featureKeys.INBOUND_INVOICES, read: true, write: true, accessLevel: accessLevels.USER },
      { featureKey: featureKeys.RECEIVERS, read: false, write: true, accessLevel: accessLevels.USER }
    ],
    permit: []
  },
  [accessLevelOverrideSupervisorWithInboundInvoices]: {
    accessLevel: accessLevels.SUPERVISOR,
    restrict: [],
    permit: [
      { featureKey: featureKeys.INBOUND_INVOICES, read: true, write: true, accessLevel: accessLevels.FINANCE },
      { routeName: featureKeys.WORK, subRoute: featureKeys.INBOUND_INVOICES, read: true, write: false, accessLevel: accessLevels.FINANCE },
      { routeName: featureKeys.PROJECTS, subRoute: featureKeys.INBOUND_INVOICES, read: true, write: false, accessLevel: accessLevels.FINANCE }
    ]
  }
} as const satisfies Record<AccessRoleOverrideConfigKeys, AccessLevelOverride>

export const getAccessRoleOverrideConfig = (accessRoleOverride: AccessRoleOverrideConfigKeys, isSalaryCalculator?: boolean) => {
  if(isSalaryCalculator) {
    return {
      ...accessRoleOverrideConfig[accessRoleOverride],
      permit: [
        ...accessRoleOverrideConfig[accessRoleOverride].permit,
        { featureKey: featureKeys.SALARY_CALCULATION, read: true, write: false },
        { routeName: 'salary_periods', read: true, write: true }
      ]
    }
  }
  return accessRoleOverrideConfig[accessRoleOverride]
}

export const getPermissionOverrideData = (
  accessRoleOverride: AccessLevelOverride,
  type: 'permit' | 'restrict',
  { featureKey, routeName, subRoute }: { featureKey?: ValueOf<typeof featureKeys>, routeName?: string, subRoute?: string }) => {
  return accessRoleOverride[type]?.find(data => {
    if('routeName' in data && routeName && 'subRoute' in data && subRoute) {
      return data.routeName === routeName && data.subRoute === subRoute
    }
    if('routeName' in data && routeName && !('subRoute' in data)) {
      return data.routeName === routeName
    }
    if('featureKey' in data && data.featureKey === featureKey) {
      return data.featureKey === featureKey
    }
    return false
  }) ||
    null
}

export const getPermissionOverrideAccessLevel = (
  permit: FeatureOrRouteAccessLevelOverride | null,
  restrict: FeatureOrRouteAccessLevelOverride | null,
  accessRoleOverride: AccessLevelOverride
) => (permit && 'accessLevel' in permit ? permit.accessLevel : null) ??
  (restrict && 'accessLevel' in restrict ? restrict.accessLevel : null) ??
  accessRoleOverride.accessLevel

export function isFeatureKey(key: unknown): key is ValueOf<typeof featureKeys> {
  return Object.values(featureKeys).includes(key as ValueOf<typeof featureKeys>)
}

export const getAccessRoleOverrideLevel = ({
  featureKey,
  routeName,
  accessRoleOverride,
  isSalaryCalculator
}: {
  featureKey?: typeof featureKeys[keyof typeof featureKeys]
  routeName?: typeof featureKeys[keyof typeof featureKeys]
  accessRoleOverride?: AccessRoleOverrideConfigKeys
  isSalaryCalculator?: boolean
}) => {
  const employeeAccessRoleOverride = accessRoleOverride ? getAccessRoleOverrideConfig(accessRoleOverride, isSalaryCalculator) : null

  if(!employeeAccessRoleOverride) {
    return null
  }

  const permit = getPermissionOverrideData(employeeAccessRoleOverride, 'permit', { featureKey, routeName })
  const restrict = getPermissionOverrideData(employeeAccessRoleOverride, 'restrict', { featureKey, routeName })

  if(!restrict && !permit) {
    return null
  }

  return getPermissionOverrideAccessLevel(permit, restrict, employeeAccessRoleOverride)
}
