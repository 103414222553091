import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router'
import { Alert, Form } from 'reactstrap'

import loginActions from '../../actions/loginActions'
import { navigateTo } from '../../actions/navigateActions'
import CancelButton from '../../components/Buttons/CancelButton'
import SaveButton from '../../components/Buttons/SaveButton'
import FormInput from '../../components/hookForm/FormInput'
import useApiForm from '../../components/hookForm/useApiForm'
import { objectValidator, stringValidators } from '../../components/hookForm/validationHelpers'
import ModalBase from '../../components/Modals/ModalBase'
import LogoutButton from '../../components/MultiFactorAuthentication/LogoutButton'
import { promisifyAction } from '../../helpers/dispatchHelpers'
import useActions from '../../hooks/useActions'
import useToggle from '../../hooks/useToggle'
import type { EveliaRootState } from '../../reducerTypes'

interface FormData {
  cuid: string | null
}

interface SystemCustomerEnvironmentLinkModalContentProps {
  cuid?: string | null
  handleCancel: () => void
  serviceLevel: string | null
  isOpen: boolean
  onSubmit: (data: FormData) => Promise<FormData>
}

const componentActions = {
  navigateTo,
  linkSystemCustomer: loginActions.linkSystemCustomerRequest
}

const SystemCustomerEnvironmentLinkModalContent = ({
  cuid = null,
  handleCancel,
  serviceLevel,
  isOpen,
  onSubmit
}: SystemCustomerEnvironmentLinkModalContentProps) => {
  const { control, submit } = useApiForm<FormData>({
    defaultValues: { cuid },
    onSubmit,
    schema: objectValidator({
      cuid: stringValidators.required('Kutsutunnus')
    })
  })
  return (
    <ModalBase
      modalProps={{
        'data-testid': 'systemCustomerEnvironmentLinkModal'
      }}
      title='Liitä käyttäjätunnus asiakasympäristöön'
      isOpen={isOpen}
      renderBody={(
        <div>
          {!serviceLevel
            ? <Alert data-testid='noActiveSystemCustomerAlert'>Käyttäjätunnus ei ole aktiivinen yhdessäkään asiakasympäristössä</Alert>
            : null}
          <Form onSubmit={submit}>
            <FormInput
              control={control}
              name='cuid'
              label='Kutsutunnus'
              required
              disabled={!!cuid}
            />
          </Form>
        </div>
      )}
      renderFooter={(
        <div className='modal-body-buttons gap-2 d-flex'>
          <SaveButton onClick={submit}>
            Liity
          </SaveButton>
          {!serviceLevel
            ? <LogoutButton />
            : (
              <CancelButton onClick={handleCancel}>
                Peruuta
              </CancelButton>
              )}

        </div>
      )}
    />
  )
}

const SystemCustomerEnvironmentLinkModal = () => {
  const location = useLocation()
  const matchRegisterRoute = matchPath<{ cuid?: string }>(location.pathname, {
    path: '/register/:cuid?',
    exact: true,
    strict: false
  })
  const actions = useActions(componentActions)
  const { data, busy } = useSelector((state: EveliaRootState) => state.whoAmI)
  const [isSystemCustomerEnvironmentLinkModalOpen, { enable: openSystemCustomerEnvironmentLinkModal, disable: closeSystemCustomerEnvironmentLinkModal }] = useToggle(false)

  useEffect(() => {
    if((!data.serviceLevel || !!matchRegisterRoute) && !busy) {
      openSystemCustomerEnvironmentLinkModal()
    } else {
      closeSystemCustomerEnvironmentLinkModal()
    }
  }, [data, matchRegisterRoute, busy, openSystemCustomerEnvironmentLinkModal, closeSystemCustomerEnvironmentLinkModal])

  const navigateTo = useCallback(data =>
    promisifyAction(data, actions.navigateTo)
  , [actions.navigateTo])

  const handleCancel = useCallback(() => {
    closeSystemCustomerEnvironmentLinkModal()
    navigateTo('/')
  }, [closeSystemCustomerEnvironmentLinkModal, navigateTo])

  const onSubmit = useCallback(data =>
    promisifyAction(data, actions.linkSystemCustomer)
  , [actions.linkSystemCustomer])

  return (
    <SystemCustomerEnvironmentLinkModalContent
      cuid={matchRegisterRoute?.params?.cuid}
      handleCancel={handleCancel}
      serviceLevel={data.serviceLevel}
      isOpen={isSystemCustomerEnvironmentLinkModalOpen}
      onSubmit={onSubmit}
    />
  )
}

export default SystemCustomerEnvironmentLinkModal
