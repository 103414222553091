import { type Action, createListenerMiddleware, type ThunkDispatch } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import authorActions from '../../actions/authorActions'
import { purchaseOrderMemoActions } from '../../slices/purchaseOrderMemoSlice'
import { getBaseQuery } from './apiHelpers'
import type { MemoModel } from './memoApi'
import type { ApiRecordResponse, ApiResponse } from './types/api'

interface MemoQueryArgs {
  memoText?: string
  id: number
  force: boolean
  memoType: 'internal' | 'supervisor'
}

export const purchaseOrderMemoApi = createApi({
  reducerPath: 'purchaseOrderMemoApi',
  baseQuery: getBaseQuery('purchase_orders'),
  endpoints: builder => ({
    getPurchaseOrderMemos: builder.query<ApiResponse<MemoModel>, MemoQueryArgs>({
      query: ({ id, memoType }) => `/${id}/memos/${memoType}`,
      forceRefetch: ({ currentArg }) => currentArg?.force ?? false
    }),
    postPurchaseOrderMemo: builder.mutation<ApiRecordResponse<MemoModel>, Required<Omit<MemoQueryArgs, 'force'>>>({
      query: ({ id, memoType, memoText }) => ({
        url: `/${id}/memos/${memoType}`,
        method: 'POST',
        body: { memoText }
      })
    })
  })
})

const handleDispatchEmbeddedData = (dispatch: ThunkDispatch<unknown, unknown, Action>, _embedded: ApiResponse<MemoModel>['_embedded']) => {
  dispatch(authorActions.fetchSuccess(_embedded.authors ?? []))
}

const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  matcher: purchaseOrderMemoApi.endpoints.getPurchaseOrderMemos.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { records, _embedded } = action?.payload ?? {}
    if(records) {
      listenerApi.dispatch(purchaseOrderMemoActions.fetchSuccess(records))
    }
    if(_embedded) {
      handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
    }
  }
})
listenerMiddleware.startListening({
  matcher: purchaseOrderMemoApi.endpoints.postPurchaseOrderMemo.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { record, _embedded } = action?.payload ?? {}
    if(action?.payload) {
      listenerApi.dispatch(purchaseOrderMemoActions.createSuccess(record))
    }
    if(_embedded) {
      handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
    }
  }
})

export const { middleware: purchaseOrderMemoMiddleware } = listenerMiddleware
export const {
  useGetPurchaseOrderMemosQuery,
  usePostPurchaseOrderMemoMutation
} = purchaseOrderMemoApi
