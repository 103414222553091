import PropTypes from 'prop-types'
import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem
} from 'reactstrap'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './header.scss'
import Feature from '../../components/Authentication/Feature'
import { UserWithoutPricePermission } from '../../components/Authentication/permissionWrappers'
import ProfileHeader from '../../components/Profile/ProfileHeader'
import NotificationsButton from '../../components/SystemMessage/NotificationsButton'
import { featureKeys } from '../../constants'
import useToggle from '../../hooks/useToggle'
import SuperSearch from '../SuperSearch/SuperSearch'
import HeaderDropdown from './HeaderDropdown'
import ServiceLevelNavLogo from './ServiceLevelNavLogo'

const Header = ({ isActive }) => {
  const [isOpen, { toggle }] = useToggle(false)

  const primarySection = (
    <Nav className='flex-grow-1 justify-content-end gap-1'>
      {isActive && (
        <UserWithoutPricePermission>
          <NavItem className='flex-grow-1 flex-md-grow-0'>
            <SuperSearch />
          </NavItem>
        </UserWithoutPricePermission>
      )}
      <Feature featureKey={featureKeys.SYSTEM_MESSAGES}>
        <NavItem>
          <NotificationsButton />
        </NavItem>
      </Feature>
      <NavItem>
        <HeaderDropdown isActive={isActive} />
      </NavItem>
    </Nav>
  )
  return (
    <header id='app-header'>
      <Navbar color='dark' dark className='d-none d-sm-flex' data-testid='header-large'>
        <Nav>
          <NavItem>
            <ServiceLevelNavLogo />
          </NavItem>
          <NavItem>
            <ProfileHeader />
          </NavItem>
        </Nav>
        {primarySection}
      </Navbar>
      {/* NOTE: different layouts based on screen size */}
      <Navbar color='dark' dark className='d-flex d-sm-none' data-testid='header-small'>
        <Nav>
          <NavItem>
            <ServiceLevelNavLogo />
          </NavItem>
        </Nav>
        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon icon={faBars} size='xs' />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav>
            <NavItem>
              <ProfileHeader />
            </NavItem>
          </Nav>
          {primarySection}
        </Collapse>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  isActive: PropTypes.bool
}

export default Header
