import { emptyArray, fileRelationTables } from '@evelia/common/constants'
import type {
  BaseIdModel,
  FileCreateBody,
  FileModel
} from '@evelia/common/types'
import {
  type BaseQueryFn,
  createApi,
  type EndpointBuilder,
  type TypedUseQuery
} from '@reduxjs/toolkit/query/react'
import isEqual from 'lodash/isEqual'
import queryString from 'query-string'

import { upload } from '../../helpers/apiHelpers'
import { promiseFileReader } from '../../helpers/helpers'
import { fileCreated, fileDeleted, filesAdded, fileUpdated } from '../../slices/filesSlice'
import { getSocket } from '../../socket'
import { getBaseQuery, getSearchQueryParams, serializePaginatedQueryArgs, transformErrorResponse } from './apiHelpers'
import { isRecordInCache } from './createCRUDApi'
import { basicApiNotification } from './rtkHelpers'
import {
  type CreateFileArgs,
  type CreateFileResponse,
  type FileDownloadResponse,
  type FileDownloadResult,
  type FileRelationType,
  fileRelationType,
  type GetFilesArgs,
  type GetFilesResponse,
  type GetRelationFilesArgs,
  type GetRelationFilesResponse,
  type NodeFile
} from './types/fileApi'

const fileApiReducerPath = 'fileApi'
const fileApiTagTypes = ['files', 'relationFiles'] as const

const socketEvents = {
  created: 'file:created',
  updated: 'file:updated',
  deleted: 'file:deleted'
}

const getFileCreateBody = ({
  file,
  extendedInfo: extendedFileInfo = {
    description: null,
    systemTag: null,
    employeeLevelId: null
  }
}: CreateFileArgs): FileCreateBody => {
  return {
    ...extendedFileInfo,
    fileName: file.name,
    fileType: file.type || 'application/octet-stream', // defaulting to default, unknown file types return nothing
    size: file.size
  }
}

const uploadToS3 = (file: NodeFile, data: { fields: object, url: string }) => {
  const formData = new FormData()
  Object.entries(data.fields).forEach(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('file', file)
  return upload(data.url, {
    body: formData
  })
}

const fileApiBase = createApi({
  reducerPath: fileApiReducerPath,
  tagTypes: fileApiTagTypes,
  baseQuery: getBaseQuery('files'),
  endpoints: builder => ({
    getFiles: builder.query<GetFilesResponse, GetFilesArgs>({
      query: args => `?${queryString.stringify(getSearchQueryParams(args))}`,
      serializeQueryArgs: serializePaginatedQueryArgs,
      transformErrorResponse,
      providesTags: ['files'],
      forceRefetch: ({ currentArg, previousArg }) => (
        currentArg?.force ||
        currentArg == null ||
        previousArg == null ||
        !isEqual(
          serializePaginatedQueryArgs({ queryArgs: currentArg }),
          serializePaginatedQueryArgs({ queryArgs: previousArg })
        )
      ),
      onQueryStarted: async(__args, { queryFulfilled, dispatch }) => {
        const fetchFiles = async() => {
          const { data: { records: files } } = await queryFulfilled
          dispatch(filesAdded(files))
        }
        basicApiNotification(fetchFiles(), {
          errorMessage: 'Virhe tiedostojen haussa',
          successMessage: null
        })
      },
      onCacheEntryAdded: async(_queryArgs, {
        cacheDataLoaded,
        cacheEntryRemoved,
        dispatch,
        updateCachedData,
        getCacheEntry
      }) => {
        const socket = getSocket()
        try {
          await cacheDataLoaded
          socket.on(socketEvents.created, (_channel, data: FileModel) => {
            // Force refetch of all pages
            updateCachedData(draft => {
              draft._embedded.options.force = true
            })
          })
          socket.on(socketEvents.updated, (_channel, data: FileModel) => {
            // Replace with updated data if found in current query cache
            if(isRecordInCache(getCacheEntry(), data.id)) {
              updateCachedData(draft => {
                draft.records = draft.records.map(
                  (file: FileModel) => file.id === data.id ? data : file
                )
              })
            }
          })
          socket.on(socketEvents.deleted, (_channel, data: BaseIdModel) => {
            // Force refresh of all pages
            updateCachedData(draft => {
              draft._embedded.options.force = true
            })
          })
        } catch{ }
        await cacheEntryRemoved
        socket.off(socketEvents.created)
        socket.off(socketEvents.updated)
        socket.off(socketEvents.deleted)
      }
    }),
    downloadFile: builder.query<FileDownloadResult, FileModel>({
      query: ({ id, fileName }) => ({
        url: `/${id}/${fileName}`,
        redirect: 'follow',
        responseHandler: async response => {
          const blob = await response.blob()
          const base64 = await promiseFileReader(blob)
          return {
            fileType: blob.type,
            base64: base64.slice(base64.indexOf(';base64,') + ';base64,'.length)
          } satisfies FileDownloadResponse
        }
      }),
      transformErrorResponse,
      transformResponse: async(
        { base64, fileType }: FileDownloadResponse,
        __meta,
        { id, fileName }
      ) => ({
        id,
        fileName,
        fileType,
        base64
      }) satisfies FileDownloadResult,
      onQueryStarted: (__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
        errorMessage: 'Virhe tiedoston lataamisessa',
        successMessage: null
      })
    }),
    createFile: builder.mutation<FileModel, CreateFileArgs>({
      query: args => ({
        url: '',
        method: 'POST',
        body: getFileCreateBody(args)
      }),
      transformErrorResponse,
      transformResponse: async({ _s3Data, ...rest }: CreateFileResponse, _meta, arg) => {
        const fileData = { ...rest } satisfies FileModel
        await uploadToS3(arg.file, _s3Data) // RTK Query transforms the response before `queryFulfilled` so upload has to be here
        return fileData
      },
      onQueryStarted: (__args, { queryFulfilled, dispatch }) => {
        const addToSliceCache = async() => {
          const { data } = await queryFulfilled
          dispatch(fileCreated(data))
        }

        return basicApiNotification(addToSliceCache(), {
          successMessage: 'Tiedosto tallennettu',
          errorMessage: 'Virhe tiedoston tallennuksessa',
          showValidationErrors: true
        })
      },
      invalidatesTags: ['files']
    }),
    updateFile: builder.mutation<FileModel, FileModel>({
      query: file => ({
        url: `/${file.id}`,
        method: 'PUT',
        body: file
      }),
      transformErrorResponse,
      onQueryStarted: (file, { queryFulfilled, dispatch }) => {
        const updateFile = async() => {
          await queryFulfilled
          dispatch(fileUpdated(file))
        }
        return basicApiNotification(updateFile(), {
          successMessage: 'Tiedosto tallennettu',
          errorMessage: 'Virhe tiedoston päivityksessä'
        })
      },
      invalidatesTags: ['files']
    }),
    deleteFile: builder.mutation<void, FileModel['id']>({
      query: fileId => ({
        url: `/${fileId}`,
        method: 'DELETE'
      }),
      transformErrorResponse,
      onQueryStarted: (fileId, { queryFulfilled, dispatch }) => {
        const deleteFile = async() => {
          await queryFulfilled
          dispatch(fileDeleted(fileId))
        }
        return basicApiNotification(deleteFile(), {
          successMessage: 'Tiedosto poistettu',
          errorMessage: 'Virhe tiedoston poistossa'
        })
      },
      invalidatesTags: ['files']
    })
  })
})

const getRelationFileEndpoint = <T extends FileRelationType>(
  builder: EndpointBuilder<BaseQueryFn, typeof fileApiTagTypes[number], typeof fileApiReducerPath>,
  relationType: T
) => builder.query<GetRelationFilesResponse<T>, GetRelationFilesArgs>({
    query: ({ relationId }) => `/${relationType}/${relationId}`,
    transformErrorResponse,
    providesTags: ['relationFiles'],
    onQueryStarted: (__arg, { queryFulfilled }) => {
      basicApiNotification(queryFulfilled, {
        errorMessage: 'Virhe tiedostojen noutamisessa',
        successMessage: null
      })
    }
  })

const fileApi = fileApiBase.injectEndpoints({
  endpoints: builder => ({
    getCustomerFiles: getRelationFileEndpoint(builder, fileRelationType.CUSTOMERS),
    getProjectFiles: getRelationFileEndpoint(builder, fileRelationType.PROJECTS)
  })
})

const fileApiExports = {
  middleware: fileApi.middleware,
  reducer: fileApi.reducer,
  reducerPath: fileApi.reducerPath,
  endpoints: fileApi.endpoints,
  util: fileApi.util
}

export { fileApiExports as fileApi }

export const {
  useCreateFileMutation,
  useUpdateFileMutation,
  useDeleteFileMutation
} = fileApi

export const useGetFiles = (args: GetFilesArgs) => fileApi.useGetFilesQuery(args, {
  selectFromResult: result => ({
    ...result,
    data: result.data?.records ?? emptyArray,
    currentData: result.currentData?.records ?? emptyArray
  })
})

type DescriptionOverrides = Partial<Record<typeof fileRelationTables[keyof typeof fileRelationTables], string>>

interface OverrideDescriptionsArgs<T extends FileRelationType> {
  record?: GetRelationFilesResponse<T>['record']
  descriptionOverrides: DescriptionOverrides
}

const overrideRelationFilesDescriptions = <T extends FileRelationType>({
  record,
  descriptionOverrides
}: OverrideDescriptionsArgs<T>): GetRelationFilesResponse<T>['record'] | null => record
    ? ({
        ...record,
        relationFiles: record.relationFiles.map(relationFile => {
          const description = descriptionOverrides[relationFile.type] ?? relationFile.description
          return { ...relationFile, description }
        })
      })
    : null

const useGetRelationFilesQuery = <T extends FileRelationType>(
  queryHook: TypedUseQuery<GetRelationFilesResponse<T>, GetRelationFilesArgs, BaseQueryFn>,
  args: GetRelationFilesArgs,
  descriptionOverrides: DescriptionOverrides
) => queryHook(args, {
    selectFromResult: ({ data, currentData, ...rest }) => ({
      ...rest,
      data: overrideRelationFilesDescriptions<T>({ record: data?.record, descriptionOverrides }),
      currentData: overrideRelationFilesDescriptions<T>({ record: currentData?.record, descriptionOverrides })
    })
  })

export const useGetCustomerFiles = (args: GetRelationFilesArgs) => useGetRelationFilesQuery(
  fileApi.useGetCustomerFilesQuery,
  args,
  { [fileRelationTables.CUSTOMER]: 'Asiakas' }
)

export const useGetProjectFiles = (args: GetRelationFilesArgs) => useGetRelationFilesQuery(
  fileApi.useGetProjectFilesQuery,
  args,
  { [fileRelationTables.CONTACT]: 'Tilaaja' }
)

export const useDownloadFile = () => fileApi.useLazyDownloadFileQuery()
