import type { FileModel } from '@evelia/common/types'
import { createEntityAdapter, createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type { EveliaRootState } from '../reducerTypes'

const fileAdapter = createEntityAdapter<FileModel>()

const initialState = fileAdapter.getInitialState()

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    filesAdded(state, action: PayloadAction<FileModel[]>) {
      fileAdapter.upsertMany(state, action.payload)
    },
    fileCreated(state, action: PayloadAction<FileModel>) {
      fileAdapter.addOne(state, action.payload)
    },
    fileUpdated(state, action: PayloadAction<FileModel>) {
      fileAdapter.upsertOne(state, action.payload)
    },
    fileDeleted(state, action: PayloadAction<FileModel['id']>) {
      fileAdapter.removeOne(state, action.payload)
    }
  }
})

export const { selectEntities: selectFileEntities, selectAll: selectFiles, selectById: selectFileById } = fileAdapter.getSelectors<EveliaRootState>(state => state[filesSlice.reducerPath])
export const { filesAdded, fileCreated, fileUpdated, fileDeleted } = filesSlice.actions

export default filesSlice
