import type { WorkCalendarEventModel } from '@evelia/common/types'
import { type Action, createListenerMiddleware, type ThunkDispatch } from '@reduxjs/toolkit'
import queryString from 'query-string'

import contactActions from '../../actions/contactActions'
import costCentreActions from '../../actions/costCentreActions'
import customerActions from '../../actions/customerActions'
import denominationActions from '../../actions/denominationActions'
import employeeActions from '../../actions/employeeActions'
import projectActions from '../../actions/projectActions'
import receiverActions from '../../actions/receiverActions'
import targetActions from '../../actions/targetActions'
import workActions from '../../actions/workActions'
import { createCRUDApi } from './createCRUDApi'
import type { EmbeddedModel, ExtraFilter } from './types/api'

const handleDispatchEmbeddedData = (dispatch: ThunkDispatch<unknown, unknown, Action>, embedded: EmbeddedModel) => {
  if(embedded.work?._embedded.receivers?.length) { dispatch(receiverActions.fetchSuccess(embedded.work._embedded.receivers)) }
  if(embedded.work?._embedded.targets?.length) { dispatch(targetActions.fetchSuccess(embedded.work._embedded.targets)) }
  if(embedded.work?._embedded.customers?.length) { dispatch(customerActions.fetchSuccess(embedded.work._embedded.customers)) }
  if(embedded.work?._embedded.projects?.length) { dispatch(projectActions.fetchSuccess(embedded.work._embedded.projects)) }
  if(embedded.work?._embedded.contacts?.length) { dispatch(contactActions.fetchSuccess(embedded.work._embedded.contacts)) }
  if(embedded.work?._embedded.workEmployees?.length) { dispatch(employeeActions.fetchSuccess(embedded.work._embedded.workEmployees)) }
  if(embedded.work?._embedded.denominations?.length) { dispatch(denominationActions.fetchSuccess(embedded.work._embedded.denominations)) }
  if(embedded.work?._embedded.costCentres?.length) { dispatch(costCentreActions.fetchSuccess(embedded.work._embedded.costCentres)) }
  if(embedded.work?.records.length) { dispatch(workActions.fetchSuccess(embedded.work.records)) }
}

const { api, useLazyRecords, useMutationsHook } = createCRUDApi<WorkCalendarEventModel, { startDate: string, endDate: string, filters: ExtraFilter[] | undefined }, 'calendarEventsWork'>({
  path: 'calendarEventsWork',
  queryPath: 'calendar_events/work',
  queryDefinition: {
    query: query => {
      const { startDate, endDate } = query
      const filters = query.filters?.flatMap(extraProp => extraProp.values?.map(value => [extraProp.field, value.value]) ?? [])
      const q = queryString.stringify({ startDate, endDate, filters })
      return `?${q}`
    }
  },
  titles: {
    singular: 'kalenterimerkintä',
    plural: 'kalenterimerkinnnät',
    genetive: 'kalenterimerkinnän',
    pluralGenetive: 'kalenterimerkintöjen'
  },
  embeddedHandler: handleDispatchEmbeddedData,
  invalidateOnCreate: true,
  invalidateOnDelete: true,
  invalidateOnUpdate: true,
  extraTagTypes: ['quick_filters']
})

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: api.endpoints.getRecords.matchFulfilled || api.endpoints.getRecord.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { _embedded } = action?.payload
    if(_embedded) {
      handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
    }
  }
})

export const workScheduleApi = api
export const useLazyWorkScheduleRecords = useLazyRecords
export const useWorkScheduleEventsMutations = useMutationsHook
export const { middleware: workScheduleMiddleware } = listenerMiddleware
