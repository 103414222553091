import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'

import { formatValidationErrorsList } from '../../helpers/helpers'
import { addErrorNotification, addSuccessNotification } from '../../helpers/notificationHelpers'

interface NotificationMessages<T, ReturnValue = undefined> {
  errorMessage?: string | null
  successMessage?: string | ((result: T) => string) | null
  onError?: () => void
  showValidationErrors?: boolean
  returnValue?: ReturnValue
  ignoreErrors?: (error) => boolean
}

const getDetailsMessage = (message: string, details: JSX.Element[]) => details ? <><b>{message}:</b><br />{details}</> : message

interface ServerMessageError {
  error: { data: { message?: string } }
}

interface ServerMessageErrorWithValidationErrors {
  error: ServerMessageError['error'] & { json: { validationErrors: { msg: string }[] } }
}

const hasServerErrorMessage = (error: unknown): error is ServerMessageError => {
  return isObject(error) && 'error' in error &&
        isObject(error.error) && 'data' in error.error &&
        isObject(error.error.data) && 'message' in error.error.data
}

const hasValidationErrors = (error: ServerMessageError): error is ServerMessageErrorWithValidationErrors => {
  return 'json' in error.error &&
    isObject(error.error.json) && 'validationErrors' in error.error.json
}

export const basicApiNotification = async<T = unknown, ReturnValue extends boolean | undefined = undefined>(queryFulfilled: Promise<T>, {
  errorMessage,
  successMessage,
  onError,
  showValidationErrors,
  returnValue,
  ignoreErrors
}: NotificationMessages<T, ReturnValue>): Promise<ReturnValue extends true ? Awaited<T | null> : void> => {
  let result: Awaited<T> | void
  try {
    result = await queryFulfilled
    if(successMessage != null) {
      if(isFunction(successMessage)) {
        addSuccessNotification(successMessage(result))
      } else {
        addSuccessNotification(successMessage)
      }
    }
    return (returnValue ? result : undefined) as ReturnValue extends true ? Awaited<T> : void
  } catch(error) {
    // queryFulfilled throws a QueryFulfilledRejectionReason error which is not exported easily
    if(ignoreErrors?.(error)) {
      return (returnValue ? null : undefined) as ReturnValue extends true ? Awaited<null> : void
    }
    onError?.()

    if(hasServerErrorMessage(error)) {
      if(hasValidationErrors(error)) {
        addErrorNotification(showValidationErrors ? getDetailsMessage(errorMessage ?? '', formatValidationErrorsList(error.error.json.validationErrors)) : error.error.data.message)
        return (returnValue ? null : undefined) as ReturnValue extends true ? Awaited<null> : void
      }
      addErrorNotification(error.error.data.message)
      return (returnValue ? null : undefined) as ReturnValue extends true ? Awaited<null> : void
    }
    if(errorMessage) {
      addErrorNotification(errorMessage)
    }
    return (returnValue ? null : undefined) as ReturnValue extends true ? Awaited<null> : void
  }
}
