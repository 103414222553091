import React, { Children } from 'react'
import { appendEitherOrEmpty } from '@evelia/common/helpers'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './_iconButton.scss'
import EveliaButton, { type EveliaButtonProps } from './EveliaButton'

export interface IconButtonProps extends EveliaButtonProps {
  icon?: IconProp
  condensed?: boolean
  IconComponent?: React.ElementType
  iconProps?: object
  href?: string
  className?: string
}

const renderContent = ({
  busyContent,
  icon,
  IconComponent,
  iconProps,
  children
}: IconButtonProps) => isBusy => {
  // Filters falsy nodes. Button would show 'text-container' even if children will not get rendered
  const validElements = Children.toArray(children)
  return (
    <>
      {(IconComponent || icon) && (
        <span className='icon-container'>
          {IconComponent ? <IconComponent icon={icon} {...iconProps} /> : <FontAwesomeIcon icon={icon!} fixedWidth {...iconProps} />}
        </span>
      )}
      {validElements.length
        ? <span className='text-container'>{busyContent && isBusy ? busyContent : children}</span>
        : <span className='empty-container' />}
    </>
  )
}
const IconButton = (props: IconButtonProps) => {
  const {
    className,
    condensed,
    icon,
    IconComponent,
    iconProps,
    children,
    dontModifyOnClick,
    ...restProps
  } = props
  const iconButtonClassName = appendEitherOrEmpty(className, `icon-button ${condensed ? 'condensed' : ''}`)
  return (
    <EveliaButton {...restProps} className={iconButtonClassName} renderContent={renderContent(props)} dontModifyOnClick={dontModifyOnClick || false}>
      {children}
    </EveliaButton>
  )
}

export default React.memo(IconButton)
