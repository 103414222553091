import { useDispatch } from 'react-redux'
import type { WageEarnerModel } from '@evelia/common/types'
import {
  createEntityAdapter
} from '@reduxjs/toolkit'
import { push, replace } from 'redux-first-history'

import { createGenericSlice } from '../helpers/sliceHelpers'
import type { EveliaRootState } from '../reducerTypes'
import { PATH_SETTINGS_WAGE_EARNERS } from '../routes'

export interface GetUrlType {
  replaceUrl?: boolean
  employeeId?: number
}

const wageEarnerAdapter = createEntityAdapter<WageEarnerModel>({})

const wageEarnerSlice = createGenericSlice({
  name: 'wageEarners',
  entityAdapter: wageEarnerAdapter
})

export const wageEarnerEntitySelectors = wageEarnerAdapter.getSelectors<EveliaRootState>(state => state.wageEarners.wageEarners)
export const wageEarnerActions = wageEarnerSlice.actions

export const getWageEarnerUrl = (wageEarnerId?: number, options?: GetUrlType) => `${PATH_SETTINGS_WAGE_EARNERS}/${wageEarnerId ?? `new/${options?.employeeId ?? ''}`}`

export const useNavigateToWageEarner = () => {
  const dispatch = useDispatch()
  return (id?: number, options?: GetUrlType) => dispatch(options?.replaceUrl ? replace(getWageEarnerUrl(id, options)) : push(getWageEarnerUrl(id, options)))
}

export default wageEarnerSlice
