import { accessLevels, emptyArray } from '@evelia/common/constants'
import { appendEitherOrEmpty, isDefined, toIdMap } from '@evelia/common/helpers'
import type { EmployeeLicenceModel, EmployeeModel, PrivateEmployeeModel } from '@evelia/common/types'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'
import type { ValueOf } from 'type-fest'

import type { EmployeeLevelModel } from '../api/rtk/employeeLevelsApi'
import type { EmployeeRolesModel } from '../api/rtk/employeeRolesApi'
import { getMemoSelector } from '../helpers/selectorHelpers'
import { createArgumentSelector, getFindItemByIdSelector, type GetListFn, type StateOrArray } from '../helpers/typedSelectorHelpers'
import type { EveliaRootState } from '../reducerTypes'
import { selectFileEntities } from '../slices/filesSlice'
import { findSystemCustomerSettings, getSalaryGroupsFromArgument } from './systemCustomerSelectors'
import { findWageEarnerWithId } from './wageEarnerSelectors'

// Employees selectors

export const getEmployeesFromArgument = createSelector(
  (arg: StateOrArray<EmployeeModel>) => Array.isArray(arg) ? arg : arg.employees.records,
  employees => sortBy(employees, ['lastName', 'firstName'])
)

interface EmployeeFilters {
  isActive?: boolean | null
  isNotResource?: boolean
  isNonBillable?: boolean
  isNotInSelectors?: boolean
  isNotInTimeRecords?: boolean
}
const keyFilter = (key: keyof EmployeeModel, value: boolean | null | undefined) => (employee: EmployeeModel) => value == null || employee[key] === value

export const findEmployees = createArgumentSelector(
  [
    getEmployeesFromArgument,
    (__state, filters: EmployeeFilters) => filters?.isActive !== undefined ? filters.isActive : true,
    (__state, filters: EmployeeFilters) => filters?.isNotResource,
    (__state, filters: EmployeeFilters) => filters?.isNonBillable,
    (__state, filters: EmployeeFilters) => filters?.isNotInSelectors,
    (__state, filters: EmployeeFilters) => filters?.isNotInTimeRecords
  ],
  (
    employees,
    isActive,
    isNotResource,
    isNonBillable,
    isNotInSelectors,
    isNotInTimeRecords
  ) => {
    const filters = [
      (employee: EmployeeModel) => employee.firstName != null && employee.lastName != null,
      keyFilter('isActive', isActive),
      keyFilter('isNotResource', isNotResource),
      keyFilter('isNonBillable', isNonBillable),
      keyFilter('isNotInSelectors', isNotInSelectors),
      keyFilter('isNotInTimeRecords', isNotInTimeRecords)
    ]
    return employees.filter(employee => filters.every(filter => filter(employee)))
  }
)

export const mapEmployeeOptions = createSelector(
  (employees: EmployeeModel[]) => employees,
  employees => employees
    .map(employee => ({ value: employee.id, text: appendEitherOrEmpty(employee.firstName, employee.lastName) }))
)

export const getEmployeeFileLinks = createArgumentSelector(
  (state: EveliaRootState) => state.employees.files.records,
  (__state: EveliaRootState, employeeId: number) => employeeId,
  (fileLinks, employeeId) => fileLinks.filter(fileLink => fileLink.employeeId === employeeId)
)

export const getFilesOfEmployee = createArgumentSelector(
  selectFileEntities,
  getEmployeeFileLinks,
  (fileEntities, employeeFileLinks) => employeeFileLinks.map(({ fileId }) => fileEntities[fileId]).filter(isDefined)
)

export const getEmployeeLicenceFileLinks = createArgumentSelector(
  (state: EveliaRootState, __employeeLicenceId) => state.employees.files.records,
  (__state, employeeLicenseId: EmployeeLicenceModel['id']) => employeeLicenseId,
  (fileLinks, employeeLicenceId) => fileLinks.filter(fileLink => fileLink.employeeLicenceId === employeeLicenceId)
)

export const getFilesOfEmployeeByEmployeeLicence = createArgumentSelector(
  selectFileEntities,
  getEmployeeLicenceFileLinks,
  (fileEntities, fileLinks) => fileLinks.map(fileLink => fileEntities[fileLink.fileId]).filter(isDefined)
)

interface GetBusinessHoursProps {
  employeeId?: number | null
  useSystemCustomerSettings?: boolean
}
export const getBusinessHours = createSelector(
  findSystemCustomerSettings,
  getSalaryGroupsFromArgument,
  (state: EveliaRootState, { employeeId, useSystemCustomerSettings }: GetBusinessHoursProps = {}) => !useSystemCustomerSettings ? employeeId ? findEmployeeWithId(state, employeeId) : findCurrentEmployee(state) : null,
  (state: EveliaRootState, { employeeId, useSystemCustomerSettings }: GetBusinessHoursProps = {}) => !useSystemCustomerSettings ? findWageEarnerWithId({ state, wageEarnerId: employeeId ?? state.whoAmI.data.employeeId }) : null,
  (__state: EveliaRootState, { useSystemCustomerSettings }: GetBusinessHoursProps = {}) => useSystemCustomerSettings,
  (systemCustomerSettings, salaryGroups, employee, wageEarner, useSystemCustomerSettings) => {
    const employeeSalaryGroup = wageEarner?.salaryGroupId ? salaryGroups.find(salaryGroup => salaryGroup.id === wageEarner.salaryGroupId) : null
    if(useSystemCustomerSettings) {
      return {
        businessHoursStart: systemCustomerSettings.settings.calendarTimeStart || systemCustomerSettings.settings.workTimeStart,
        businessHoursEnd: systemCustomerSettings.settings.calendarTimeEnd || systemCustomerSettings.settings.workTimeEnd
      }
    }

    return ({
      businessHoursStart: employee?.workTimeStart || employeeSalaryGroup?.workTimeStart || systemCustomerSettings.settings.workTimeStart,
      businessHoursEnd: employee?.workTimeEnd || employeeSalaryGroup?.workTimeEnd || systemCustomerSettings.settings.workTimeEnd
    })
  }
)

// Employee selectors

export const findEmployeeWithId = getFindItemByIdSelector(getEmployeesFromArgument)

export const findCurrentEmployee = (state: EveliaRootState) => findEmployeeWithId(state, state.whoAmI.data.employeeId) as PrivateEmployeeModel

export const findEmployeeNameWithId = createSelector(
  (state: EveliaRootState, employeeId: number) => findEmployeeWithId(state, employeeId),
  employee => employee ? `${employee.firstName} ${employee.lastName}` : ''
)

export const findCurrentEmployeeName = createSelector(
  findCurrentEmployee,
  employee => employee ? `${employee.firstName} ${employee.lastName}` : ''
)

export const getEmployeeLicencesByEmployeeId = createArgumentSelector(
  [
    state => state.employees.employeeLicences.records,
    (__state, employeeId: number | string) => Number(employeeId)
  ],
  (employeeLicences, employeeId) => employeeLicences.filter(employeeLicence => employeeLicence.employeeId === employeeId)
)

export const getIsOwn = createArgumentSelector(
  [
    findCurrentEmployee,
    (__state, employeeId: number) => employeeId
  ],
  (currentEmployee, employeeId) => currentEmployee.id === employeeId
)

// Level selectors

const fallbackEmployeeLevel: EmployeeLevelModel = { id: -1, name: 'Fallback', accessLevel: accessLevels.DEFAULT }
export const getEmployeeLevelsFromArgument: GetListFn<EmployeeLevelModel> = arg => Array.isArray(arg) ? arg : arg.employees.levels.records

export const findEmployeeLevelWithId = getFindItemByIdSelector(getEmployeeLevelsFromArgument, fallbackEmployeeLevel)

export const getEmployeeLevelsMap = createSelector(
  getEmployeeLevelsFromArgument,
  employeeLevels => toIdMap(employeeLevels)
)

export const getEmployeeLevelsSorted = createSelector(
  getEmployeeLevelsFromArgument,
  levels => sortBy(levels, ['orderByAccessLevel', 'orderByPriority'], ['asc', 'asc'])
)

export const getDefaultEmployeeLevelsSorted = createSelector(
  getEmployeeLevelsFromArgument,
  levels => {
    const defaultEmployeeLevel = levels.filter(employeeLevel => !employeeLevel.accessRoleOverride)
    return sortBy(defaultEmployeeLevel, 'accessLevel')
  }
)

export const findCurrentEmployeeLevel = createSelector(
  findCurrentEmployee,
  (state: EveliaRootState) => state.employees.levels.records,
  (employee, employeeLevels) => (employee ? findEmployeeLevelWithId(employeeLevels, employee.employeeLevelId) : fallbackEmployeeLevel) ?? fallbackEmployeeLevel
)

export const getUserSelectableEmployeeLevels = createSelector(
  getEmployeeLevelsSorted,
  findCurrentEmployeeLevel,
  (employeeLevels, currentEmployeeLevel) => {
    return employeeLevels.filter(({ accessLevel }) => currentEmployeeLevel.accessLevel >= accessLevel && accessLevel >= 0)
  }
)

export const getHasAccessLevel = createArgumentSelector(
  [
    findCurrentEmployeeLevel,
    (__state, minAccessLevel: ValueOf<typeof accessLevels>) => minAccessLevel
  ],
  (employeeAccessLevel, minAccessLevel) => employeeAccessLevel.accessLevel >= minAccessLevel
)

export const getIsSupervisor = createSelector(
  state => getHasAccessLevel(state, accessLevels.SUPERVISOR),
  isSupervisor => isSupervisor
)

// Role selectors

export const getEmployeeRolesOfEmployee = createArgumentSelector(
  [
    findEmployeeWithId,
    (state: EveliaRootState) => state.employeeRoles.records
  ],
  (employee, employeeRoles) => employee ? employeeRoles.filter(employeeRole => employee.employeeRoleIds.includes(employeeRole.id)) : emptyArray
)

export const findEmployeesWithRoles = createArgumentSelector(
  [
    (state: EveliaRootState, __employeeRoles, employees?: EmployeeModel[]) => employees || getEmployeesFromArgument(state),
    (__state, employeeRoles: EmployeeRolesModel[] | number[], __employees) => employeeRoles.map(employeeRole => Number.isInteger(employeeRole) ? employeeRole : employeeRole.id)
  ],
  (employees, employeeRoleIds) => {
    return employees.filter(employee => employeeRoleIds.some(employeeRoleId => employee.employeeRoleIds.includes(employeeRoleId)))
  }
)

// Memo selectors

export const findMemosByEmployeeId = getMemoSelector('employees', 'employeeId')

export const findPrivateEmployee = createArgumentSelector(
  [
    findEmployeeWithId,
    // @ts-expect-error This is fixed when root state is correctly typed
    state => state.employees.extras.records
  ],
  (employee, extras) => {
    const extraData = extras.find(({ id }) => id === employee?.id)
    return extraData
      ? {
          ...extraData,
          ...employee
        } as PrivateEmployeeModel
      : null
  }
)
